import { cn } from '../../utils/cn';

export const KeyboardIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      viewBox='0 0 17 11'
      className={cn('w-3.5 h-3.5 fill-current', className)}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.949 0C15.1771 0 16.1733 0.96712 16.1733 2.16112V8.83888C16.1733 10.0321 15.1779 11 13.949 11H2.22427C0.996158 11 0 10.0329 0 8.83888V2.16112C0 0.967874 0.995382 0 2.22427 0H13.949ZM13.949 1.17852H2.2235C1.66554 1.17852 1.21296 1.61825 1.21296 2.1611V8.83886C1.21296 9.38172 1.66554 9.82144 2.22425 9.82144H13.9498C14.5077 9.82144 14.9603 9.38172 14.9603 8.83886V2.1611C14.9603 1.61825 14.5077 1.17852 13.949 1.17852ZM3.23481 3.92889C2.89973 3.92889 2.62833 3.66447 2.62833 3.33964C2.62833 3.01406 2.89973 2.75038 3.23481 2.75038H4.85183C5.18692 2.75038 5.45831 3.01406 5.45831 3.33964C5.45831 3.66447 5.18692 3.92889 4.85183 3.92889H3.23481ZM11.119 6.28593C10.7839 6.28593 10.5126 6.02151 10.5126 5.69667C10.5126 5.3711 10.7839 5.10741 11.119 5.10741H12.7361C13.0711 5.10741 13.3425 5.3711 13.3425 5.69667C13.3425 6.02151 13.0711 6.28593 12.7361 6.28593H11.119ZM7.0761 6.28593C6.74102 6.28593 6.46962 6.02151 6.46962 5.69667C6.46962 5.3711 6.74102 5.10741 7.0761 5.10741H8.69312C9.02821 5.10741 9.2996 5.3711 9.2996 5.69667C9.2996 6.02151 9.02821 6.28593 8.69312 6.28593H7.0761ZM5.55991 8.83888C5.22482 8.83888 4.95343 8.5752 4.95343 8.24962C4.95343 7.92479 5.22482 7.66036 5.55991 7.66036H10.6142C10.9492 7.66036 11.2206 7.92479 11.2206 8.24962C11.2206 8.5752 10.9492 8.83888 10.6142 8.83888H5.55991ZM7.0761 3.92889C6.74102 3.92889 6.46962 3.66447 6.46962 3.33964C6.46962 3.01406 6.74102 2.75038 7.0761 2.75038H8.69312C9.02821 2.75038 9.2996 3.01406 9.2996 3.33964C9.2996 3.66447 9.02821 3.92889 8.69312 3.92889H7.0761ZM11.119 3.92889C10.7839 3.92889 10.5126 3.66447 10.5126 3.33964C10.5126 3.01406 10.7839 2.75038 11.119 2.75038H12.7361C13.0711 2.75038 13.3425 3.01406 13.3425 3.33964C13.3425 3.66447 13.0711 3.92889 12.7361 3.92889H11.119ZM3.23481 6.28593C2.89973 6.28593 2.62833 6.02151 2.62833 5.69667C2.62833 5.3711 2.89973 5.10741 3.23481 5.10741H4.85183C5.18692 5.10741 5.45831 5.3711 5.45831 5.69667C5.45831 6.02151 5.18692 6.28593 4.85183 6.28593H3.23481Z'
      />
    </svg>
  );
};
